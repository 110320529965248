exports.linkResolver = (doc) => {
  if (doc.type === "project") {
    return `/projects/${doc.uid}`;
  }

  if (doc.type === "page") {
    return `/${doc.uid}`;
  }

  return "/";
};
